<template>
    <section class="main-content-section">
        <div class="grid">
            <LoadingSpinner v-if="isLoading"/>
            <div v-else class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="form-container authentication">
                    
                    <h1 class="page-title">{{forgotYourPassword}}</h1>
                    <div class="info">{{fillInTheFormBelowToRequestANewPassword}}</div>
                    
                    <form @submit.prevent="submitForm()">
                        <div class="form-row flex flex-column">
                            <label for="email">{{emailAddress}} *</label>
                            <input 
                                id="email" 
                                type="email"
                                v-model.trim="email"
                                @input="$v.email.$touch()"
                                :class="{ error: $v.email.$error }" 
                            />
                            <div v-if="$v.email.$dirty">
                                <p class="error" v-if="!$v.email.email">{{validEmailAddress}}</p>
                                <p class="error" v-if="!$v.email.required">{{emailAddress}} {{isRequired}}</p>
                            </div>
                        </div>

                        <div class="form-row">
                            <span class="small">* {{indicatesRequiredField}}</span>
                        </div>

                        <div class="form-row flex justify-center">
                            <button type="submit" class="btn btn-primary alternative submitBtn">{{sendPassword}}</button>
                        </div>                      
                    </form>
                </div>
            </div>
        </div>
        <WarningMessageModal v-show="showWarningModal" :title="errorTitle" :text="errorMessage" @closeModal="closeModal()"/>
    </section>
</template>

<script>
// import Vue from 'vue';
// import { VueReCaptcha } from 'vue-recaptcha-v3';
// Vue.use(VueReCaptcha, { siteKey: '6Lcc9AMcAAAAAIihkgnQZdN3NShD-7T-QEQwxBm_', loaderOptions: { useEnterprise: true } });

import { mapGetters } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import WarningMessageModal from '@/components/modals/WarningMessageModal';
import LoadingSpinner from '../../components/ui/LoadingSpinner.vue';
import agent from '../../api/agent';

export default {
    name: 'ForgotPassword',
    components: {WarningMessageModal, LoadingSpinner},
    data() {
        return {
            email: '',
            errorTitle: '',
            errorMessage: '',
            showWarningModal: false,
            isLoading: false
        }
    },

    validations: {
        email: { email, required },
    },

    created() {
        this.setDocumentTitle();
    },

    updated() {
        this.setDocumentTitle();
    },

    computed: {
        ...mapGetters([
            'metaTitle',
            'selectedLanguage',
            'recaptchaValidation',
            // Texts
            'forgotYourPassword', 
            'fillInTheFormBelowToRequestANewPassword', 
            'isRequired', 
            'indicatesRequiredField', 
            'notAllRequiredFieldsAreCompleted', 
            'emailAddress', 
            'validEmailAddress', 
            'confirmEmailaddress', 
            'incorrectEmailAddressConfirmation', 
            'sendPassword',
            'thankYou',
            'pleaseCheckYourInbox'
        ]),
    },

    methods: {
        setDocumentTitle() {
            document.title =  this.forgotYourPassword + this.metaTitle;
        },

        showModal() {
            this.showWarningModal = true;
        },

        closeModal() {
            this.showWarningModal = false;
        },

        async resetPassword() {
            try {
                await agent.users.forgotPassword({email: this.email, languageNumber: this.selectedLanguage});

                this.errorTitle = this.thankYou;
                this.errorMessage = this.pleaseCheckYourInbox;
                this.showModal();
            } catch (error) {
                console.error(error);
            }
        },

        async validateRecaptcha(token) {
            await this.$store.dispatch('validateRecaptcha', token);
            this.isLoading = false;
            if (this.recaptchaValidation === true) {
                this.resetPassword();
            } else {
                this.errorTitle = 'Google Recaptcha failed';
                this.errorMessage = 'Please try again';
                this.showModal();
            }
        },

        async getRecaptchaToken() {
            this.isLoading = true;
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('login');
            this.validateRecaptcha(token);
        },

        submitForm() {
            if (this.$v.email.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.emailAddress + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if(!this.$v.$invalid) {
                this.getRecaptchaToken();
            }
        }
    }
}
</script>